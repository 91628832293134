.pulse {
	animation: pulse 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(.8);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(.8);
	}
}
