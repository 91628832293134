:root {
	color-scheme: only light !important;
}

@font-face {
	font-family: "Arcon-Regular";
	src: url("../fonts/arcon/Arcon-Regular.otf");
	font-weight: normal;
	font-display: block;
	font-style: normal;
}

@font-face {
	font-family: "Archivo";
	src: url("../fonts/archivo/Archivo-VariableFont_wdth\,wght.ttf");
	font-weight: normal;
	font-display: block;
	font-style: normal;
}

html {
	background-color: $body-bg !important;
	position: relative !important;
	min-height: 100% !important;
	height: 100% !important;
	body {
		padding-top: $nav-link-height + ($navbar-padding-y * 2) !important;
		background-color: $body-bg !important;
		position: relative !important;
		height: 100% !important;
		#root {
			height: 100% !important;
			@include media-breakpoint-down(md) {
				height: auto !important;
			}
			main.App-main {
				padding-bottom: 60px !important;
			}
		}
		&.page-user {
			background: #e4e4e4 !important;
			.App-main {
				background: #e4e4e4 !important;
			}
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: Archivo !important;
	color: $orange !important;
	// text-transform: capitalize;
}

.overflow-md-visible {
	@include media-breakpoint-up(md) {
		overflow: visible !important;
	}
}

.w-md-100 {
	@include media-breakpoint-up(md) {
		width: 100% !important;
	}
}

.w-sm-100 {
	@include media-breakpoint-down(md) {
		width: 100% !important;
	}
}

.ff-arcon-regular {
	font-family: "Arcon-Regular";
}

.fs-xs {
	font-size: 12px;
}

.fs-md-xs {
	font-size: $font-size-base;
	@include media-breakpoint-down(md) {
		font-size: 12px;
	}
}

.input-number input::-webkit-outer-spin-button,
.input-number input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.input-number input[type=number] {
	appearance: textfield;
}

.form-control:focus {
	box-shadow: none;
}

.alert {
	.caret {
		position: relative;
		margin-left: -10px;
		&::before {
			border-top: 10px solid var(--bs-alert-bg);
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			position: absolute;
			content: '';
			left: 0px;
			top: 0;
		}
	}
}

.search {
	.bi,
	input::placeholder {
		color: lighten($body-bg, 50%) !important;
	}
}

.nav-dropdown-toggle {
	.dropdown-toggle {
		&::after {
			content: none !important;
		}
	}
}

.btn-flat {
	background-color: purple;
	color: white;
}

.btn-xxl {
	padding: 1rem 1.5rem;
	font-size: 1.5rem;
}

.footer {
	border-top: 6px solid lighten($primary, 6%) !important;
	background: $primary !important;
	color: $light !important;
	z-index: 999 !important;
}

.navbar {
	height: 80 !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
	box-shadow: $box-shadow-lg !important;
	color: $secondary !important;
}

.offcanvas-menu {
	width: 80% !important;
	@include media-breakpoint-up(md) {
		width: 100% !important;
		background: none !important;
	}
}

.offcanvas-menu {
	.offcanvas-nav {
		.active {
			background: rgba(darken($primary, 20%), 20%) !important;
		}
	}
}

.offcanvas-body {
	margin-left: auto;
	@include media-breakpoint-up(md) {
		margin-left: 25% !important;
	}
}

.d-linebreak {
	white-space: pre-line !important;
}

.sections {
	h3 {
		font-size: 3rem !important;
		img {
			width: 50px !important;
		}
		@include media-breakpoint-down(md) {
			font-size: 2.4rem !important;
			img {
				width: 40px !important;
			}
		}
	}
}

.banner {
	background-image: url("../banner.png");
	background-position: center;
	background-size: cover;
	min-height: 800px;
	@include media-breakpoint-down(md) {
		background-image: url("../banner-sm.png");
		min-height: 400px;
	}
}

.carousel-banner {
	.carousel-banner-item {
		background-position: center;
		background-size: cover;
		min-height: 800px;
		@include media-breakpoint-down(md) {
			min-height: 400px;
		}		
	}
}

.shadow-in {
	box-shadow: inset 0 1rem 3rem rgba($black, 10%);
}
