.spinner {
	margin-bottom: 20px;
	margin-top: 20px;
	height: 100%;
	width: 100%;
	&.spinner-fixed {
		justify-content: center;
		flex-direction: column;
		align-items: center;
		position: fixed;
		z-index: 99999;
		margin: 0;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
		.spinner-inner {
			position: relative;
			height: 100vh;
			width: 100%;
			&:before {
				background-color: rgba(#4e4e4e, 60%);
				position: absolute;
				content: " ";
				height: 100%;
				width: 100%;
				z-index: -1;
				bottom: 0;
				right: 0;
				left: 0;
				top: 0; 
			}
			.spinner-element,
			img {
				margin-left: -20px;
				position: fixed;
				height: 40px;
				width: 40px;
				left: 50%;
				top: 50%;
			}
		}
	}	
	.spinner-inner {
		text-align: center;
		width: 100%;
		.spinner-element,
		img {
			margin-left: -20px;
			height: 40px;
			width: 40px;
		}
	}
}
