@import "bootstrap/scss/functions";

// Default variable overrides
$enable-caret: false;
$enable-shadows: false;
$enable-cssgrid: false;
$enable-gradients: false;

$font-family-base: "Archivo";
// $font-size-base: 0.8rem;

$blue: #084995;
$orange: #E05206;
$green: #2b905b;
$dark: #002249;
$black: #121212;
$white: #fefefe;

$gray-100: lighten($black, 90%);
$gray-200: lighten($black, 80%);
$gray-300: lighten($black, 70%);
$gray-400: lighten($black, 60%);
$gray-500: lighten($black, 60%);
$gray-600: lighten($black, 50%);
$gray-700: lighten($black, 40%);
$gray-800: lighten($black, 30%);
$gray-900: lighten($black, 20%);

$primary: $blue;
$secondary: $gray-200;
$body-bg: $white;
$body-color: darken($body-bg, 80%);

$form-label-color: $body-color;
$form-select-focus-width: 0;
$input-bg: darken($body-bg, 2%);
$input-color: darken($input-bg, 60%);
$input-border-color: darken($input-bg, 20%);
$input-focus-border-color: $input-border-color;

// $nav-link-padding-y: 1.8rem;

// $nav-link-height + $navbar-padding-y  !important;

// $box-shadow-lg: 0 0 2rem rgba($black, 40%);

// Icons
@import "bootstrap-icons/font/bootstrap-icons";
// Bootswatch
@import "bootswatch/dist/united/variables";

// Configuration
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

$grays: (
	"gray-100": $gray-100,
	"gray-200": $gray-200,
	"gray-300": $gray-300,
	"gray-400": $gray-400,
	"gray-500": $gray-500,
	"gray-600": $gray-600,
	"gray-700": $gray-700,
	"gray-800": $gray-800,
	"gray-900": $gray-900
);

$new-container-max-widths: (
	xl: 1000px,
	xxl: 1100px,
);

$new-grid-breakpoints: (
	xl: 1100px,
	xxl: 1200px,
);

$grid-breakpoints: map-merge($grid-breakpoints, $new-grid-breakpoints);
$container-max-widths: map-merge($container-max-widths, $new-container-max-widths);

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$all-colors: map-merge-multiple($blues, $indigos, $purples, $pinks, $reds, $oranges, $yellows, $greens, $teals, $cyans, $grays);
//  @error $all-colors;
// $all-colors-rgb: map-loop($all-colors, to-rgb, "$value");
// @error $all-colors-rgb;

$utilities: map-merge($utilities, ("color": map-merge(map-get($utilities, "color"), (values: map-merge(map-get(map-get($utilities, "color"), "values"), ($all-colors ))))));
$utilities: map-merge($utilities, ("background-color": map-merge(map-get($utilities, "background-color"), (values: map-merge(map-get(map-get($utilities, "background-color"), "values"), ($all-colors ))))));
// $utilities: map-merge($utilities, ("bg-opacity": map-merge(map-get($utilities, "bg-opacity"), (values: map-merge(map-get(map-get($utilities, "bg-opacity"), "values"), ($all-colors ))))));

// @import "bootstrap/scss/bootstrap";

// Configuration
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/helpers";

@import "bootstrap/scss/utilities/api";

@import "bootswatch/dist/united/bootswatch";

@import './scrollToTop.scss';
@import './pulse.scss';
@import "./App";
