.scroll-to-top {
	z-index: 99999 !important;
	padding: 4px 14px;
	position: fixed;
	bottom: 0;
	right: 0;
	@include media-breakpoint-down(md) {
		padding: 14px;
		bottom: 60px;
	}
	.scroll-to-top-btn {
		background: none;
		padding: 5px;
		border: 0;
		.bi {
			color: $orange !important;
		}
	}
}
